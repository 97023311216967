import Link from 'next/link'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import BrandLogo from './BrandLogo'
import styles from '../../styles/components/AppIntroduction.module.css'
import SubscribeViaEmailForm from '../SubscribeViaEmailForm'
import Nav from 'react-bootstrap/Nav'
import { NavLink } from 'react-bootstrap'
import { useRouter } from 'next/router'
import AppMarketplaceLink from '../AppMarketplaceLink'

const Footer = () => {
  const router = useRouter()

  return (
    <div className='py-4 py-lg-5 px-4 px-lg-5 my-3 bg-primary text-white br-3 mt-5'>
      <Row>
        <Col sm={12} md={3}>
          <div className='mb-4 mb-md-5'>
            <BrandLogo big />
          </div>

          <div
            className={`${styles.marketplaces} d-flex gap-2 flex-row flex-md-column w-100 w-md-50 mb-5 mb-md-0`}
            style={{ maxWidth: 200 }}
          >
            <AppMarketplaceLink marketplace='apple' />
            <AppMarketplaceLink marketplace='google' />
          </div>
        </Col>
        <Col sm={0} md={2} />
        <Col sm={12} md={3}>
          <div
            style={{ padding: '0.5rem 2rem', paddingTop: 0, paddingLeft: 0 }}
            className='fw-semibold fs-4'
          >
            Explore
          </div>
          <Nav
            className='d-flex flex-row flex-md-column mb-5 mb-md-0'
            activeKey={router.pathname}
          >
            <NavLink href='/product' className='ps-0'>Product</NavLink>
            <NavLink href='/pricing' className='ps-0'>Pricing</NavLink>
            <NavLink href='/resources' className='ps-0'>Resources</NavLink>
            <NavLink href='/blog' className='ps-0'>Blog</NavLink>
            <NavLink href='/faq' className='ps-0'>FAQ</NavLink>
            <NavLink href='/about' className='ps-0'>About</NavLink>
          </Nav>
        </Col>
        <Col
          sm={12} md={4}
          className='d-flex flex-column'
        >
          <div>
            <SubscribeViaEmailForm shownInFooter />
          </div>
        </Col>
        <Col
          xs={12}
          className='text-center mt-5'
        >
          <div
            className="border border-1 border-light border-opacity-10 mb-5"
          />
          <div className='mb-3'>
            We are on a mission to create opportunities for people.
          </div>
          <div className=''>
            © 2023 — Ivy Flip — <Link href='/legal/privacy-policy' prefetch={false}>View Privacy Policy</Link>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Footer