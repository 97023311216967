import * as gtag from './gtag'
import * as fbq from './fbq'

export const requestFreeAcquired = (userId) => {
  gtag.event('free_trial_acquired')
  fbq.track('free_trial_acquired', { userId })
}

export const requestPlanChange = (userId) => {
  gtag.event('plan_change_acquired')
  fbq.track('plan_change_acquired', { userId })
}

export const clickedOnMarketplaceLink = (marketplace) => {
  gtag.event('clicked_on_marketplace_link')
  fbq.track('clicked_on_marketplace_link', { marketplace })
}

export const subscribeToNewsletter = (email) => {
  gtag.event('subscribed_to_newsletter')
  fbq.track('subscribed_to_newsletter', { email })
}

export const submitCouchPickup = () => {
  gtag.event('couch_pickup_submitted')
  fbq.track('couch_pickup_submitted')
}

export const submitIvyIndustryApplication = () => {
  gtag.event('ivy_industry_application_submitted')
  fbq.track('ivy_industry_application_submitted')
}

export const couchPickupCallButtonClicked = () => {
  gtag.event('couch_sms_button_clicked')
  fbq.track('couch_sms_button_clicked')
}

export const flashSaleClicked = () => {
  gtag.event('flash_sale_cta_clicked')
  fbq.track('flash_sale_cta_clicked')
}
