import Image from 'next/image'

const BrandLogo = ({ big = false }) => {
  return (
    <div className='d-flex align-items-center gap-2'>
      <Image
        src='/img/logo-transparent.svg'
        alt='Ivy Flip'
        width={big ? 48 : 32}
        height={big ? 48 : 32}
        layout='fixed'
        priority
      />
      <span
        className={'fw-semibold ' + (big ? 'fs-3' : '')}
      >Ivy Flip</span>
    </div>
  )
}

export default BrandLogo
