import { initFirebase } from '../lib/firebase'
import { EmptyLayout } from '../components/layouts/empty-layout'
import '../styles/bootstrap.min.css'
import 'react-datepicker/dist/react-datepicker.css'
import '../styles/date-picker.css'
import '../styles/gloabl.css'
import { useRouter } from 'next/router'
import { useEffect, useMemo } from 'react'
import * as gtag from '../lib/gtag'
import { AuthAction, withUser } from 'next-firebase-auth'
import { useFirestoreUser, UserProvider } from '../hooks/useFirestoreUser'
import Head from 'next/head'
import Script from 'next/script'
import NextNProgress from 'nextjs-progressbar'
import { Toaster } from 'react-hot-toast'
import { StoreItemsProvider } from '../hooks/useStoreItems'
import StoreLayout from '../components/layouts/StoreLayout'
import LayoutLandings from '../components/layouts/LayoutLandings'
import LayoutApp from '../components/layouts/LayoutApp'
import ErrorBoundary from '../components/ErrorBoundaty'

initFirebase()

const RedditPixel = () => {
  const user = useFirestoreUser()
  const userEmailAdvancedMatchingEntry = useMemo(() => user?.email ? `,"email":"${user.email}"` : '', [user])
  const externalIdAdvancedMatchingEntry = useMemo(() => user?.id ? `"externalId":"${user.id}"` : '', [user])

  return (
    <Script
      strategy="lazyOnload"
      dangerouslySetInnerHTML={{
        __html: `!function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);rdt('init','${process.env.REDDIT_ADVERTISER_ID}', {"optOut":false,"useDecimalCurrencyValues":true${userEmailAdvancedMatchingEntry}${externalIdAdvancedMatchingEntry}});rdt('track', 'PageVisit');`
      }}
    />
  )
}

const App = ({ Component, pageProps }) => {
  const router = useRouter()

  const enableLayout = router.query['enable-layout'] !== 'false' &&
    localStorage.getItem('enable-layout') !== 'false' &&
    !['/couch-pickup'].includes(router.asPath)

  if (enableLayout === false) {
    localStorage.setItem('enable-layout', 'false')
  }

  const onStorePages = router.asPath.startsWith('/store') &&
    !router.asPath.startsWith('/store/settings') &&
    !router.asPath.startsWith('/store/inventory')

  const onIndex = !(router.asPath.startsWith('/app') && router.asPath !== '/app/signin') &&
    !router.asPath.startsWith('/store') &&
    !router.asPath.startsWith('/pickup/feed')

  const Layout = enableLayout
    ? (
      onStorePages
        ? StoreLayout
        : (
          onIndex
            ? LayoutLandings
            : LayoutApp
        )
    )
    : EmptyLayout

  // Set up the loading progress bar
  useEffect(() => {
    router.events.on('routeChangeComplete', gtag.pageview)
    return () => {
      router.events.off('routeChangeComplete', gtag.pageview)
    }
  }, [router.events])

  useEffect(() => {
    if (['/app/sales-tracker'].includes(router.asPath)) {
      document.body.style.backgroundColor = '#f0f1f3'
    } else if (router.asPath.startsWith('/pricing')) {
      document.body.style.backgroundColor = '#f0f3f1'
      // document.body.style.backgroundColor = '#57C064'
    } else {
      document.body.style.backgroundColor = 'white'
    }
  }, [router.asPath])

  return (
    <div>
      <ErrorBoundary>
        <UserProvider>
          <StoreItemsProvider>
            <Head>
              <title>Ivy Flip</title>
              <link rel='icon' href='/favicon.ico' />
              <meta
                property='og:locale'
                content='en_EN'
                key='og:locale'
              />
              <meta
                property='og:site_name'
                content='Ivy'
                key='og:site_name'
              />
              <meta
                property='og:type'
                content='website'
                key='og:type'
              />
              <meta name='viewport' content='width=device-width,initial-scale=1' />
              <link rel='preconnect' href='https://fonts.googleapis.com' />
              <link rel='preconnect' href='https://fonts.gstatic.com' crossOrigin="crossOrigin" />
              <link href='https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap' rel='stylesheet' />
              <RedditPixel />
            </Head>
            <Layout>
              <NextNProgress color="#4bbf73" />
              <Toaster position="bottom-left" />
              <Component {...pageProps} />
            </Layout>
          </StoreItemsProvider>
        </UserProvider>
      </ErrorBoundary>
    </div>
  )
}

export default withUser({
  whenUnauthedBeforeInit: AuthAction.SHOW_LOADER,
  Loader: () => null
})(App)
