import * as track from '../lib/track'

const marketplaces = {
  apple: {
    link: 'https://apps.apple.com/us/app/ivy-flip/id6446943060',
    image: '/img/landing/marketplaces/app-store.png',
    title: 'App Store'
  },

  google: {
    link: 'https://play.google.com/store/apps/details?id=com.ivyflip.mobile',
    image: '/img/landing/marketplaces/play-store.png',
    title: 'Google Play'
  }
}

const AppMarketplaceLink = ({ marketplace = 'apple', withImage = true }) => {
  const data = marketplaces[marketplace] ?? marketplaces.apple

  return (
    <a
      href={data.link}
      target='_blank'
      onClick={() => {
        track.clickedOnMarketplaceLink(marketplace)
      }}
    >
      {withImage && (
        <img
          src={data.image}
          alt={`Ivy Flip Application ${data.title} | Ivy Flip`}
          className='w-100'
        />
      )}
      {!withImage && (marketplace === 'apple' ? 'iOS' : 'Android')}
    </a>
  )
}

export default AppMarketplaceLink