import * as fns from 'firebase/functions'
import { firebaseFunctions } from './firebase'
import { IProfile, IUpdateProfileOptions } from '../../interfaces/profile.interface'

export async function updateMyProfile (profile: IProfile, options: IUpdateProfileOptions) {
  let response = await fns.httpsCallable(firebaseFunctions, 'profile-updateMyProfile')({
    profile,
    options
  })

  return response.data
}

export async function contactSeller (itemId: string, storeId: string, message: string, phoneNumber: string, email: string) {
  let response = await fns.httpsCallable(firebaseFunctions, 'ivystore-contactSeller')({
    itemId,
    storeId,
    message,
    phoneNumber,
    email
  })

  return response.data
}

export async function notifyError (message: string, trace: string, file: string, url: string, type: string = 'other') {
  let response = await fns.httpsCallable(firebaseFunctions, 'app-notifyError')({
    message,
    trace,
    file,
    url,
    type
  })

  return response.data
}
