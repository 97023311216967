// Usage:
// 1. Add this file as /lib/gtag.js
// 2. Make sure to set env var GA_TRACKING_ID (aka Measurement ID, for GA4)
// 3. Track page views using router.events in _app.js
// 4. Inject the global site tag code in _document.js

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
  try {
    window.gtag('config', process.env.GA_TRACKING_ID, {
      page_path: url
    })
  } catch {}
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = (action, category, label, value) => {
  try {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value
    })
  } catch {}
}
