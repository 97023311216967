import Container from 'react-bootstrap/Container'
import { useRouter } from 'next/router'

interface EmptyLayoutProps {
  children: React.ReactNode
}

export function EmptyLayout({ children }: EmptyLayoutProps): JSX.Element {
  const router = useRouter()
  const backgroundColor = router.query['bg']

  return (
    <Container className={`d-flex flex-column py-3 ${backgroundColor ? `bg-${backgroundColor}` : '' }`}>
      {children}
    </Container>
  )
}
