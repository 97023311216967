import NavDropdown from 'react-bootstrap/NavDropdown'
import NavDropdownLink from './NavDropdownLink'

export default function MenuItemsList ({ items, divide = false, title }) {
  const availableItems =  items

  if (availableItems.length === 0) {
    return null
  }

  return (
    <>
      {divide && <NavDropdown.Divider />}
      {title && <NavDropdown.Header>{title}</NavDropdown.Header>}
      {availableItems.map(item => (
        <NavDropdownLink
          key={item.href}
          href={item.href}
        >
          {item.label}
        </NavDropdownLink>
      ))}
    </>
  )
}