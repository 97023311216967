import Container from 'react-bootstrap/Container'
import Link from 'next/link'
import Navbar from 'react-bootstrap/Navbar'
import { useIvystore, useIvystoreInfo } from '../../hooks/useStoreItems'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useUser } from 'next-firebase-auth'
import Button from 'react-bootstrap/Button'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FooterSmall from './FooterSmall'

const StoreLayout = ({ children }) => {
  const authUser = useUser()

  const router = useRouter()
  const slug = router.query.slug

  const ivystoreInfo = useIvystoreInfo(slug)
  const myIvyStore = useIvystore(authUser.id)

  const [storeData, setStoreData] = useState(null)
  const [isMyStore, setIsMyStore] = useState(false)

  useEffect(() => {
    if (ivystoreInfo) {
      setStoreData(ivystoreInfo)

      if (myIvyStore?.id === ivystoreInfo.id) {
        setIsMyStore(true)
      }
    }
  }, [ ivystoreInfo, myIvyStore ])

  return (
    <>
      <Navbar
        bg='transparent'
        variant='light'
        expand='md'
        className='py-3'
        collapseOnSelect
      >
        <Container>
          <div className={'d-flex w-100 justify-content-center align-items-center gap-3 gap-lg-4'}>
            {/* <Link
              href='/'
              prefetch={false}
            >
              <div
                className={'fs-4'}
                style={{ whiteSpace: 'nowrap'}}
              >
                <BrandLogo />
              </div>
            </Link> */}

            {storeData && (
              <>
                {/* <div className={'fs-4 opacity-75 text-dark fw-lighter'}>
                  /
                </div> */}
                <div className={'fs-5 position-relative'}>
                  <Link href={`/store/${storeData.slug}`}>
                    <a
                      className={'text-decoration-none text-black d-inline-block'}
                      style={{ lineHeight: 1.2 }}
                    >
                      {storeData.name}
                    </a>
                  </Link>

                  {isMyStore && (
                    <Button
                      variant={'light'}
                      size={'sm'}
                      href={`/store/settings`}
                      className={'position-absolute'}
                      style={{ right: -50 }}
                    >
                      <FontAwesomeIcon
                        icon={faCog}
                        width={16}
                        height={16}
                      />
                    </Button>
                  )}
                </div>
              </>
            )}
          </div>
        </Container>
      </Navbar>
      <Container className='d-flex flex-column'>
        {children}
        <FooterSmall />
      </Container>
    </>
  )
}

export default StoreLayout
