import Link from 'next/link'
import NavDropdown from 'react-bootstrap/NavDropdown'

export default function NavDropdownLink ({ href, children }) {
  return (
    <Link href={href} prefetch={false}>
      <NavDropdown.Item className='' href={href}>
        {children}
      </NavDropdown.Item>
    </Link>
  )
}
