import React from "react"
import { notifyError } from '../lib/functions.helper'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null
    }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error }
  }

  componentDidCatch(error, errorInfo) {
    console.log({ error, errorInfo })
    this.setState({ error, errorInfo })

    const message = error?.message ?? error?.toString()
    notifyError(message, errorInfo?.componentStack?.toString()?.split('\n').slice(0, 3).join('\n'), error?.fileName, window?.location?.href, 'other')
      .catch((error) => {
        console.error('Error sending error notification', error)
        console.log('error', error)
        console.log('file', error?.fileName)
        console.log('message', message)
        console.log('stack', error?.stack)
      })
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={styles.error}>
          <style>
            {`
              body {
                margin: 0;
                color: #000;
                background: #fff;
              }
              .next-error-h1 {
                border-right: 1px solid rgba(0, 0, 0, .3);
              }

              @media (prefers-color-scheme: dark) {
                body {
                  color: #fff;
                  background: #000;
                }
                .next-error-h1 {
                  border-right: 1px solid rgba(255, 255, 255, .3);
                }
              }`
            }
          </style>
          <h2 style={styles.h2}>Application error: a client-side exception has occurred (see the browser console for more information).</h2>
          <div style={styles.desc}>
            {/* {this.state.error.toString()} */}
          </div>
        </div>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary

const styles = {
  error: {
      fontFamily: '-apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", "Fira Sans", Avenir, "Helvetica Neue", "Lucida Grande", sans-serif',
      height: '100vh',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
  },
  desc: {
      display: 'inline-block',
      textAlign: 'left',
      lineHeight: '49px',
      height: '49px',
      verticalAlign: 'middle'
  },
  h1: {
      display: 'inline-block',
      margin: 0,
      marginRight: '20px',
      padding: '0 23px 0 0',
      fontSize: '24px',
      fontWeight: 500,
      verticalAlign: 'top',
      lineHeight: '49px'
  },
  h2: {
      fontSize: '14px',
      fontWeight: 'normal',
      lineHeight: '49px',
      margin: 0,
      padding: 0
  }
};