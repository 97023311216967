import { useUser } from 'next-firebase-auth'
import * as db from 'firebase/firestore'
import { createContext, useContext, useEffect, useState } from 'react'

const userContext = createContext(null)

export function UserProvider ({ children }) {
  return (
    <userContext.Provider value={useUserProvider()}>
      {children}
    </userContext.Provider>
  )
};

export const useFirestoreUser = () => useContext(userContext)

const useUserProvider = () => {
  const authUser = useUser()

  const localUser = JSON.parse(window.localStorage.getItem('firestoreUser'), (key, value) => {
    // Firestore.Timestamp values get serialized into {seconds:, nanoseconds:}
    // so we should recreate a Firestore.Timestamp here
    if (value && value.seconds && value.nanoseconds && Object.keys(value).length === 2) { return new db.Timestamp(value.seconds, value.nanoseconds) }
    return value
  }) || { id: null }
  const [user, setUser] = useState(localUser)

  useEffect(() => {
    async function setupAuthUser () {
      if (!authUser.id) {
        const userToSet = { id: null }
        window.localStorage.setItem('firestoreUser', JSON.stringify(userToSet))
        setUser(userToSet)
      } else {
        // Look up or create user document in Firebase
        const userDocRef = db.doc(db.getFirestore(), 'users', authUser.id)
        
        // Listen for changes
        return db.onSnapshot(userDocRef, (d) => {
          const userToSet = d.exists() ? {
            ...d.data(),
            id: authUser.id
          } : {
            id: null
          }
          window.localStorage.setItem('firestoreUser', JSON.stringify(userToSet))
          setUser(userToSet)
        })
      }
    }

    setupAuthUser()
  }, [
    authUser
  ])

  return user
}
